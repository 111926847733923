import { RPP_QUESTIONS } from "@app/shared/constants/rpp-survey-questions-data";
import { RppQuestionAnswer } from "./rpp-question-answer.mode";
import { RppQuestionType } from "./rpp-question-type.model";

export interface RppAnswerData {
  id: number;
  answer: RppQuestionAnswer;
}

export interface RppData {
  totalAnswered?: number;
  answersData?: Record<RppQuestionType, RppAnswerData>;
}

export class RppDataModel implements RppData {
  totalAnswered = 0;
  answersData: Record<RppQuestionType, RppAnswerData>;

  constructor() {
    this.answersData = Object.fromEntries(
      RPP_QUESTIONS.map((question) => [
        question.translation,
        {
          id: question.id,
          answer: RppQuestionAnswer.NO_ANSWER,
        },
      ]),
    ) as Record<RppQuestionType, RppAnswerData>;
  }
}
